import React from 'react'

const Icon = ( { children } ) => {
  return (
    <div>
      { children }
    </div>
  )
}

export default Icon
